document.addEventListener("turbolinks:load", () => {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })
    const moment = require("moment");

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new Popover(popoverTriggerEl)
    })

    const sidebarToggle = document.body.querySelector('#sidebarToggle');
    if (sidebarToggle) {
        // Uncomment Below to persist sidebar toggle between refreshes
        // if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
        //     document.body.classList.toggle('sb-sidenav-toggled');
        // }
        sidebarToggle.addEventListener('click', event => {
            event.preventDefault();
            document.body.classList.toggle('sb-sidenav-toggled');
            localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
        });
    }

    $('.accordion-button-sidebar').on('click', function () {
        location.href = $(this).data('href');
    })

    $('#item-search-input').on('keyup', function (event) {
        if (event.keyCode === 13) {
            $('#item-search').click();
        } else {
            let val = '';
            if ($(this).val() != '') {
                val = '?item_search=' + $(this).val();
            }
            $('#item-search').data('href', $('#item-search').data('path') + val);
        }
    })

    $('#item-search').on('click', function () {
        location.href = $('#item-search').data('href');
    })

    $('#brand-search-input').on('keyup', function (event) {
        if (event.keyCode === 13) {
            $('#brand-search').click();
        } else {
            let val = '';
            if ($(this).val() != '') {
                val = '?brand_search=' + $(this).val();
            }
            $('#brand-search').data('href', $('#brand-search').data('path') + val);
        }
    })

    $('#brand-search').on('click', function () {
        location.href = $('#brand-search').data('href');
    })

    $('#brand-item-search-input').on('keyup', function (event) {
        if (event.keyCode === 13) {
            $('#brand-item-search').click();
        } else {
            let val = '';
            if ($(this).val() != '') {
                val = '?brand_item_search=' + $(this).val();
            }
            $('#brand-item-search').data('href', $('#brand-item-search').data('path') + val);
        }
    })

    $('#brand-item-search').on('click', function () {
        location.href = $('#brand-item-search').data('href');
    })

    $('.clear-order-filter').on('click', function () {
        location.href = $('#po-search').data('path');
    })

    $('#order-search-input').on('keyup', function (event) {
        if (event.keyCode === 13) {
            $('#po-search').click();
        }
    })

    $('#order_filter_state, #order_filter_status, #order_filter_account, #order_filter_vendor, #order_filter_brand').on('change', function () {
        set_order_filters();
        $('#po-search').click();
    })

    $('#order_filter_customer').on('change', function () {
        set_custom_order_filters();
    })

    $('#pagination_filter').on('change', function () {
        set_order_filters();
        $('#po-search').click();
        // location.href = $(this).data('href') + "?per=" + $(this).val();
    })

    $('#po-search').on('click', function () {
        set_order_filters();
        location.href = $('#po-search').data('href')
    })

    $('.download-btn').on('click', function (event) {
        event.currentTarget.parentNode.parentNode.getElementsByClassName('loading-spinner-none')[0].classList.replace("loading-spinner-none","loading-spinner");
    })

    let start = moment();
    let end = moment();
    if ($('#start_date_order').text() !== '') {
        start = moment($('#start_date_order').text(), 'YYYY-MM-DD')
    }
    if ($('#end_date_order').text() !== '') {
        end = moment($('#end_date_order').text(), 'YYYY-MM-DD')
    }
    $('#ebay_user_filter').on('change', function () {
        if ($('#ebay_user_filter').val() !== '' && $('#ebay_user_filter').length > 0) {
            location.search = `account_id=${$('#ebay_user_filter').val()}`
        }
    })
    
    function set_custom_order_filters() {
        let val = '';
        if ($('#order_filter_customer').length > 0 && $('#order_filter_customer').val() !== '') {
            val = `${val === '' ? '' : `${val}&`}customer=${$('#order_filter_customer').val()}`
        }
        location.href = location.origin + $('#custom-orders').data('href') + `?${val}`
    }

    function set_order_filters(p_start = '', p_end = '') {
        let val = '';
        if ($('#order-search-input').val() != '') {
            val = `&po_search=${$('#order-search-input').val()}`
        }
        if ($('#start_date_order').length > 0) {
            if (p_start !== '' && p_end !== '') {
                val = `${val === '' ? '' : `${val}&`}start=${p_start}&end=${p_end}`
            } else {
                val = `${val === '' ? '' : `${val}&`}start=${start.format('YYYY-MM-DD')}&end=${end.format('YYYY-MM-DD')}`
            }
        }
        if ($('#order_filter_state').length > 0 && $('#order_filter_state').val() !== '') {
            val = `${val === '' ? '' : `${val}&`}state=${$('#order_filter_state').val()}`
        }
        if ($('#order_filter_status').length > 0 && $('#order_filter_status').val() !== '') {
            val = `${val === '' ? '' : `${val}&`}status=${$('#order_filter_status').val()}`
        }
        if ($('#order_filter_vendor').length > 0 && $('#order_filter_vendor').val() !== '') {
            val = `${val === '' ? '' : `${val}&`}vendor=${$('#order_filter_vendor').val()}`
        }
        if ($('#order_filter_brand').length > 0 && $('#order_filter_brand').val() !== '') {
            val = `${val === '' ? '' : `${val}&`}brand=${$('#order_filter_brand').val()}`
        }
        if ($('#order_filter_account').length > 0 && $('#order_filter_account').val() !== '') {
            val = `${val === '' ? '' : `${val}&`}account=${$('#order_filter_account').val()}`
        }
        if ($('#pagination_filter').length > 0 && $('#pagination_filter').val() !== '') {
            val = `${val === '' ? '' : `${val}&`}per=${$('#pagination_filter').val()}`
        }
        let url = new URL(location.href)

        if (url.searchParams.get("direction") !== null) {
            val = `${val === '' ? '' : `${val}&`}direction=${url.searchParams.get("direction")}`
        }
        if (url.searchParams.get("sort") !== null) {
            val = `${val === '' ? '' : `${val}&`}sort=${url.searchParams.get("sort")}`
        }
        $('#po-search').data('href', $('#po-search').data('path') + `${$('#po-search').data('path').includes('?') ? val : '?' + val}`);
    }

    $('.daterange').daterangepicker({
        "startDate": start,
        "endDate": end,
        "autoApply": true,
        "opens": "center",
        "buttonClasses": "btn-info",
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
    }, function (start, end) {
        set_order_filters(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
        location.href = $('#po-search').data('href');
    });

    // let lastScrollTop = 0;
    // $(window).scroll(function(event){
    //     let st = $(this).scrollTop();
    //     if (st > lastScrollTop){
    //         console.log('down');
    //         sidebarToggle.click();
    //     } else {
    //         console.log('up');
    //     }
    //     lastScrollTop = st;
    // });

    $('.role-change').on('change', function () {
        if (this.selectedIndex == 1)
            $('.vendor-form')[0].style['display'] = 'block';
        else
            $('.vendor-form')[0].style['display'] = '';
    })
    $(".role-change").trigger("change");

    $('.table-responsive').on('show.bs.dropdown', function () {
        $('.table-responsive').css( "overflow", "inherit" );
    });
      
    $('.table-responsive').on('hide.bs.dropdown', function () {
        $('.table-responsive').css( "overflow", "auto" );
    })

    $("tr[data-link]").click(function() {
        window.location = $(this).data("link")
    })

    $('.tracking-btn').on('ajax:success', function(event) {
        if(event.detail[0]['status']){
            this.innerHTML = "<i class='fas fa-1x fa-check-circle'></i>Pushed";
            this.classList.add('disabled');
        }else {
            this.innerHTML = "<i class='fas fa-1x fa-times-circle'></i>Try again";
        }
    });
    
    $('#has-account').on('change', check_portal_id_selection);
    check_portal_id_selection();

    function check_portal_id_selection() {
        if($('#has-account')[0] && $('#has-account')[0].checked && $('#portal')[0].classList.contains("hidden")) {
            $('#portal')[0].classList.remove("hidden");
        }else if ($('#portal')[0] && !$('#portal')[0].classList.contains("hidden")) {
            $('#portal')[0].classList.add("hidden");
        }
    }
    $(".checkbox-class").on("click", function (){
        $(this)[0].form.submit();
    });
})
