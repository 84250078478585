document.addEventListener("turbolinks:load", () => {

  var any_scan_done = false;

  $(".clickable-row").click(function() {
      window.location = $(this).data("href");
  });

  $(".accordion-item").click(function() {
    $(`#${$(this).data('id')}`).prop('checked', true);
  });

  $(".create_shipping_transaction").click(function() {
    let package_id = $(this).data("id")
    $(`#spinner-rate-${package_id}`).removeClass('d-none');
    $.ajax({
      url: `/orders/${$('#ship_order_id').val()}/create_transaction`,
      type: "post",
      data: {
        rate_object_id: $(`.shipping_rate_select_${package_id}:checked`).val(),
        carrier: $(`.shipping_rate_select_${package_id}:checked`).data('provider'),
        package_id: package_id
      },
      success: function(response) {
        $(`#spinner-rate-${package_id}`).addClass('d-none');
        if(response["error"]){
          $(`#transaction-error-${package_id}`).text(response["error"])
          $(`#transaction-error-${package_id}`).removeClass('d-none')
        }else if (response["success"]) {
          $(`#transaction-error-${package_id}`).text('');
          $(`#transaction-error-${package_id}`).addClass('d-none');
          location.reload();
        }
      },
      error: function(xhr) {
      }
    });
  });

  $(".create_return_shipping_transaction").click(function() {
    let package_id = $(this).data("id")
    $(`#spinner-rate-${package_id}`).removeClass('d-none');
    $.ajax({
      url: `/return_labels/${$('#return_label_id').val()}/create_transaction`,
      type: "post",
      data: {
        rate_object_id: $(`.shipping_rate_select_${package_id}:checked`).val(),
        carrier: $(`.shipping_rate_select_${package_id}:checked`).data('provider'),
        package_id: package_id
      },
      success: function(response) {
        $(`#spinner-rate-${package_id}`).addClass('d-none');
        if(response["error"]){
          $(`#transaction-error-${package_id}`).text(response["error"])
          $(`#transaction-error-${package_id}`).removeClass('d-none')
        }else if (response["success"]) {
          $(`#transaction-error-${package_id}`).text('');
          $(`#transaction-error-${package_id}`).addClass('d-none');
          location.reload();
        }
      },
      error: function(xhr) {
      }
    });
  });

  $(".create_custom_shipment_transaction").click(function() {
    let package_id = $(this).data("id")
    $(`#spinner-rate-${package_id}`).removeClass('d-none');
    $.ajax({
      url: `/custom_shipments/${$('#custom_shipment_label_id').val()}/create_transaction`,
      type: "post",
      data: {
        rate_object_id: $(`.shipping_rate_select_${package_id}:checked`).val(),
        carrier: $(`.shipping_rate_select_${package_id}:checked`).data('provider'),
        package_id: package_id
      },
      success: function(response) {
        $(`#spinner-rate-${package_id}`).addClass('d-none');
        if(response["error"]){
          $(`#transaction-error-${package_id}`).text(response["error"])
          $(`#transaction-error-${package_id}`).removeClass('d-none')
        }else if (response["success"]) {
          $(`#transaction-error-${package_id}`).text('');
          $(`#transaction-error-${package_id}`).addClass('d-none');
          location.reload();
        }
      },
      error: function(xhr) {
      }
    });
  });

  $(".create_vendor_shipment_transaction").click(function() {
    let package_id = $(this).data("id")
    $(`#spinner-rate-${package_id}`).removeClass('d-none');
    $.ajax({
      url: `/vendor_shipments/${$('#vendor_shipment_label_id').val()}/create_transaction`,
      type: "post",
      data: {
        rate_object_id: $(`.shipping_rate_select_${package_id}:checked`).val(),
        carrier: $(`.shipping_rate_select_${package_id}:checked`).data('provider'),
        package_id: package_id
      },
      success: function(response) {
        $(`#spinner-rate-${package_id}`).addClass('d-none');
        if(response["error"]){
          $(`#transaction-error-${package_id}`).text(response["error"])
          $(`#transaction-error-${package_id}`).removeClass('d-none')
        }else if (response["success"]) {
          $(`#transaction-error-${package_id}`).text('');
          $(`#transaction-error-${package_id}`).addClass('d-none');
          location.reload();
        }
      },
      error: function(xhr) {
      }
    });
  });

  if ($('#order_scanning_modal').length > 0){
    if($('#start_order_scan').data('show-scanning') == true){
      $('#start_order_scan').click();
    }
    var scanning_modal = document.getElementById('order_scanning_modal');
    $('#display_scanned_item').attr('src', $(`#0_scan_image`).attr('src'))

    scanning_modal.addEventListener('hidden.bs.modal', function (event) {
      if(any_scan_done){
        location.reload();
      }
    });

    scanning_modal.addEventListener('shown.bs.modal', function (event) {
      $(`#item_scan_input`).focus();
    });
  }

  $('.scanning_process_input').on('paste keyup', function (event) {
    if(event.keyCode === 13) {
      var element = this;
      let elem_id = $(element).data('id');
      setTimeout(function () {
        var text = $(element).val();
        if($(`#${elem_id}_upc`).text() == text){
          $(`#item_scan_input`).val('')
          $(`#item_scan_input`).focus();
          $(`#scanned_count`).text(parseInt($(`#scanned_count`).text()) + 1);
          any_scan_done = true;
          if ($(`#scanned_count`).text() == $(`#qty_to_scan_count`).text()){
            $(`#scanned_count`).addClass('text-success')
            $(`#qty_to_scan_count`).addClass('text-success')
            $(`#scanned_count`).removeClass('text-danger')
            $(`#qty_to_scan_count`).removeClass('text-danger')
          }
          $.ajax({
            url: `/orders/${$(element).data('order-id')}/scanned`,
            type: "post",
            data: {
              order_item_id: $(element).data('order-item-id')
            },
            success: function(response) {
              if ($(`#scanned_count`).text() == $(`#qty_to_scan_count`).text()){
                $(`#order_scanning_modal_close`).click();
                $(`#${elem_id}_scan_success`).removeClass('d-none');
                $(`#${elem_id}_scan`).addClass('d-none');
              }
            },
            error: function(xhr) {
            }
          });
        }else{
          $(`#${elem_id}_not_matched`).removeClass('d-none');
          $(`#${elem_id}_scan_success`).addClass('d-none');
        }
      }, 150);
    }
  });
})
