import consumer from "./consumer"

// if ( window.location.href.includes('http://localhost:3000/items') || ( window.location.href.includes('http://localhost:3000/brands') && window.location.href.split('/').length == 4) ) 
if ( window.location.href.includes('https://buffyhub.com/items') || ( window.location.href.includes('https://buffyhub.com/brands') && window.location.href.split('/').length == 4) )
{
  consumer.subscriptions.create("ExportChannel", {
    connected() {
      // localStorage.removeItem('exporting');
    },
    disconnected() {},
    received(data) {
      let csv_download_link = document.createElement('a');
      csv_download_link.href = data['path'];
      csv_download_link.download = data['file_name'];
      csv_download_link.click();
      if($('#notice')[0])
        $('#notice')[0].remove();
      if($('.loading-spinner')[0])
        $('.loading-spinner')[0].classList.replace('loading-spinner', 'loading-spinner-none');
      if($('.items-page')[0])
        location.reload();
    }
  });
}
